import React, { useContext, useEffect, useState } from 'react';
import { ModalPayCommissionProps, OptionProps, PaymentOption, SelectControlledOption } from '~/types';
import ControlledSelect from '~/components/input/ControlledSelect';
import { calcCommission, getPaymentOptions } from '~/service/NegotiationService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import MinCommissionValue from '~/components/mensagens/MinCommissionValue';
import { showAlertDanger } from '~/components/notification';
/* import { AuthContext } from '~/context/AuthContext';
import SelectControlled from '~/components/input/SelectControlled'; */

interface FormInput {
  fpa_codigo: number;
}

const ModalPayCommission: React.FunctionComponent<ModalPayCommissionProps> = ({
  idModalComplement,
  emp_codigo,
  onSubmit,
  negotiation,
}) => {
/*   const validationSchema: yup.SchemaOf<FormInput> = yup.object().shape({
    fpa_codigo: yup.number().required(),
  });

  const { handleSubmit, errors, control, watch, setValue } = useForm<FormInput>({
    resolver: yupResolver(validationSchema),
  }); */

  const [isAwaitResponse, setIsAwaitResponse] = useState(false);
  const [isAwaitPaymentOptions, setIsAwaitPaymentOptions] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState([] as OptionProps[]);
  const [paymentOptionsChecked, setPaymentOptionsChecked] = useState([] as SelectControlledOption[]);

  const [commission, setCommission] = useState<number | null>(null);
  const [isLoadingCommission, setIsLoadingCommission] = useState(false);
  const [isShowCommissionMessage, setIsShowCommissionMessage] = useState(false);

  const fpa_codigo =26681;

/*   useEffect(() => {
    if (!!emp_codigo) fetchPaymentOptions();
  }, [emp_codigo]);

  useEffect(() => {
    if (!!negotiation && !!watch('fpa_codigo')) handleCalcCommission();
  }, [negotiation]);

  useEffect(() => {

    if (commission !== null && commission < 5 && paymentOptions.length > 0) {
      setPaymentOptionsChecked(
        paymentOptions.map(({ label, ...props }) => ({
          label,
          isDisabled: ['cartão', 'cartao'].includes(label.toLowerCase()),
          ...props,
        }))
      );
    } else {
      setPaymentOptionsChecked(paymentOptions);
    }
  }, [commission, paymentOptions]); */

  useEffect(() => {
    const paymentMethod = paymentOptions.find(
      (paymentMethod) => paymentMethod.value === fpa_codigo && paymentMethod.label === 'Boleto'
    );

    if (!!paymentMethod) {
      setIsShowCommissionMessage(!!commission && commission < 10);
    } else {
      setIsShowCommissionMessage(false);
    }
  }, [fpa_codigo, commission]);

/*   const fetchPaymentOptions = async () => {
    setIsAwaitPaymentOptions(true);

    const {
      payload: { formasPgto },
    } = await getPaymentOptions(emp_codigo);

    setPaymentOptions(formasPgto.map((f: PaymentOption) => ({ value: f.FPA_CODIGO, label: f.FPA_DESCRICAO })));
    setIsAwaitPaymentOptions(false);
  }; */

  const HandleOnSubmit = async () => {
    setIsAwaitResponse(true);
    await onSubmit(fpa_codigo);
    setIsAwaitResponse(false);
  };

  const handleCalcCommission = async () => {
    if (!negotiation) {
      showAlertDanger({ message: 'Erro interno. Por favor, tente novamente.' });
    } else if (negotiation.valor == '0' || negotiation.valor == null) {
      setCommission(0);
    } else {
      setIsLoadingCommission(true);
      const { success, message, payload } = await calcCommission({
        ofe_codigo: negotiation.ofe_codigo,
        emp_codigo: negotiation.emp_codigo,
        e_compra: !!negotiation.e_compra,
        valor: negotiation.valor,
        e_vpmix: negotiation.e_vpmix,
      });

      if (success) {
        setIsLoadingCommission(false);
        setCommission(+payload.comissao);
      } else {
        showAlertDanger({ message: message });
        return '';
      }
    }
  };

  return (
    <div
      className="modal fade"
      id={`ModalPayCommission${idModalComplement || ''}`}
      role="dialog"
      aria-labelledby="responderLabel"
      aria-hidden="true"
      tabIndex={-1}
    >
      <div className="modal-dialog" role="document" style={{ paddingTop: '100px' }}>
        <div className="modal-content">
          <div className="modal-body" style={{ paddingTop: 0 }}>
            <div className="row">
              <div className="col-md-12">
                <div className="card stacked-form">
                  <div className="card-header ">
                    <h4 className="card-title">Confirmar Permuta?</h4>
                  </div>
                  <div className="card-body " style={{ paddingTop: 0 }}>
                      <>

                      <h4 className="text-center mt-2">Deseja permutar está oferta?</h4>
                        <form
                          id={`modalPayCommissionFormId${idModalComplement || ''}`}
                        >
                          <input type="hidden"  name="fpa_codigo" value={26681} />
                  
                        </form>
                         {isShowCommissionMessage && <MinCommissionValue />} 
                      </>
                  </div>
                 {/*  <div className="card-body " style={{ paddingTop: 0 }}>
                    {negotiation?.isPaid ? (
                      <div className="text-center mt-5">
                        <i className="far fa-thumbs-up text-success" style={{ fontSize: '30pt' }} />
                        <h4 className="mt-2">A comissão já foi paga.</h4>
                      </div>
                    ) : (
                      <>
                        <form
                          id={`modalPayCommissionFormId${idModalComplement || ''}`}
                          onSubmit={handleSubmit(HandleOnSubmit)}
                        >
                          <SelectControlled
                            label="Forma de pagamento"
                            name="fpa_codigo"
                            control={control}
                            defaultValue={''}
                            isRequired
                            hasError={!!errors.fpa_codigo}
                            placeholder="Escolha..."
                            options={paymentOptionsChecked}
                            isDisabled={isAwaitPaymentOptions || isLoadingCommission}
                            isLoading={isAwaitPaymentOptions || isLoadingCommission}
                          />
                        </form>
                        {isShowCommissionMessage && <MinCommissionValue />}
                      </>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
              <i className="fa fa-arrow-left"></i> Voltar
            </button>
            {!negotiation?.isPaid && (
              <button
                type="button"
                onClick={HandleOnSubmit}
                className={`btn btn-success btn-wd ${isAwaitResponse && 'btn-outline'}`}
                form={`modalPayCommissionFormId${idModalComplement || ''}`}
                disabled={isAwaitResponse}
              >
                {isAwaitResponse ? (
                  <>
                    <i className="fas fa-spinner rotating" /> Processando...
                  </>
                ) : (
                  <>
                    <i className="fa fa-save"></i> Confirmar
                  </>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalPayCommission;
