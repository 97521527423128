import React, { useContext, useEffect, useState } from 'react';

import * as Util from '~/utils/util';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Menu from '../../../components/menu';
import { ConfigVPMIX, GoodOffered, Offer, PaymentOption } from '~/types';
import { getMyOffers, getMyOffersFilterData } from '~/service/OfferService';
import { AuthContext } from '~/context/AuthContext';
import { formatAndCompareDate, openModal } from '~/utils/util';
import PageLoading from '~/components/loading/PageLoading';
import ModalCreateTranfer from '~/components/modal/ModalCreateOffer/ModalCreateTranfer';
import { getDashboardTotalizadoresAssociado } from '~/service/DashboardService';

const CriarOfertas: React.FC = () => {
  const [configModal, setConfigModal] = useState(
    {} as { config_vpmix: ConfigVPMIX; formasPgto: PaymentOption[]; segmentos: GoodOffered[] }
  );

  const { company: empresa } = useContext(AuthContext);
  const [offer, setOffer] = useState<(Offer & { isCopy?: 'S' | 'N' | '' }) | null>(null);
  const [offers, setOffers] = useState([] as Array<Offer>);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingModal, setIsLoadingModal] = useState(true);
  const [cardCredit, setCardCredit] = useState(0);

  const [filters, setFilters] = useState({
    filterGoodsOffered: [] as Array<{ value: string }>,
    filterSituation: [] as Array<{ value: string }>,
    filterWithValue: true,
    filterToCombine: true,
    filterVP: 'PADRAO',
    filterVPMIX: 'VPMIX',
  });

  useEffect(() => {
    fetchMyOffers();
    fetchMyOffersFilterData();

    getDashboardTotalizadoresAssociado({ emp_codigo: empresa.EMP_CODIGO }).then(({ payload }) => {
      const { limiteAprovado, valorCompra, valorVenda, alertaNegocio } = payload;

      const regulator = +alertaNegocio;
      const limit = +limiteAprovado;
      const buys = +valorCompra;
      const sells = +valorVenda;

      const credit = limit + (buys + sells - regulator);

      setCardCredit(credit);
    });
  }, []);

  const fetchMyOffersFilterData = async () => {
    setIsLoadingModal(true);
    const { payload } = await getMyOffersFilterData(empresa.EMP_CODIGO);
    setConfigModal(payload);
    setIsLoadingModal(false);
  };

  const fetchMyOffers = async () => {
    setOffer(null);
    setIsLoading(true);
    let situacao = '(';
    filters.filterSituation.forEach((s, i) => {
      i === 0 ? (situacao += `'${s.value}'`) : (situacao += `,'${s.value}'`);
    });
    situacao += ')';

    const { payload } = await getMyOffers({
      emp_codigo: empresa.EMP_CODIGO,
      seg_codigo: 1,
      situacao: filters.filterSituation.length === 0 ? null : situacao,
      ofe_tipo: `('${filters.filterVP}', '${filters.filterVPMIX}')`,
      com_valor: filters.filterWithValue,
      a_combinar: filters.filterToCombine,
    });

    setOffers(payload.ofertas.sort((a: Offer, b: Offer) => formatAndCompareDate(b.OFE_DATA, a.OFE_DATA)));

    setIsLoading(false);
  };

  const onSelectOffer = (offer: Offer | null) => {
    setOffer(offer);
    openModal('criarOferta');
  };

  return (
    <div className="wrapper">
      <Menu />

      <div className="main-panel">
        <Header title="Minhas Ofertas" showBalance />

        <div className="content">
          {isLoading && <PageLoading />}
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card table-big-boy">
                  <div className="card-header ">
                    <h4 className="card-title">Transferência de créditos</h4>

                    <button
                      onClick={() => onSelectOffer(null)}
                      type="button"
                      className="btn btn-sm btn-success btn-outline"
                      style={{ marginTop: 10 }}
                    >
                      <span className="btn-label">
                        <i className="fa fa-plus"></i>
                      </span>
                      nova transferência
                    </button>
                  </div>

                  <ModalCreateTranfer
                    isLoading={isLoadingModal}
                    config={configModal}
                    offer={offer}
                    fetchMyOffers={fetchMyOffers}
                    onConfirm={setOffer}
                    creditos={cardCredit}
                    setCreditos={setCardCredit}
                  />
                  <div className="card-body table-full-width">
                    <div className="table-responsive">
                      <table className="table table-sm table-hover">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Associado</th>
                            <th>Data Tranferência</th>
                            <th className="text-center">Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <tr>
                              <td colSpan={3}></td>
                            </tr>
                          ) : offers.length === 0 ? (
                            <tr>
                              <td colSpan={3}>
                                <p className="text-color-default">Nenhuma transferência encontrada.</p>
                              </td>
                            </tr>
                          ) : (
                            offers.map((offer) => (
                              <tr key={offer.OFE_CODIGO}>
                                <td className="">{offer.OFE_CODIGO}</td>
                                <td className="td-name">{offer.OFE_ASSOCIADO}</td>
                                <td className="td-name">{offer.OFE_DATA.split(' ')[0]}</td>
                                <td className="td-number text-center">
                                  {Util.formatNumberToMoney(Util.correctNumberWithComma(offer.OFE_VALOR))}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CriarOfertas;
