import React, { useContext, useEffect, useState } from "react";
import * as Util from "~/utils/util";

import { AuthContext } from "~/context/AuthContext";
import { getOffers } from "~/service/OfferService";
import { Offer, QuoteFormData } from "~/types";

import { useErrorHandler } from "react-error-boundary";
import { showAlertDanger, showAlertSuccess } from "~/components/notification";
import ModalRequestQuote from "./ModalRequestQuote";
import ModalOfferDetails from "~/components/modal/ModalOfferDetails";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  requestQuoteAndPermuteOfferBuy,
  requestQuote,
  checkBuyOfferToday,
  calcCommission,
} from "~/service/NegotiationService";
import ModalPayCommission from "~/components/modal/ModalPayCommission";

import ViewVerOfertas from "~/views/verOfertas";
import ModalFilterAvailableOffers from "~/views/ModalFilterAvailableOffers";
import { submitPaymentAsaas } from "~/service/PaymentAsaas";
import { OfferType } from "~/enum";

const VerOfertas: React.FunctionComponent = () => {
  const handleError = useErrorHandler();

  const {
    user: usuario,
    company: empresa,
    changeBalance,
    balance,
  } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [offer, setOffer] = useState({} as Offer);
  const [offers, setOffers] = useState([] as Array<Offer>);
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [alert, setAlert] = useState<JSX.Element>();
  const [sweetAlertBuyToday, setSweetAlertBuyToday] = useState<JSX.Element>();

  const [filteredOffers, setFilteredOffers] = useState([] as Array<Offer>);

  const [filters, setFilters] = useState({
    filterSegment: [] as Array<{ value: string }>,
    filterCity: [] as Array<{ value: string }>,
    filterFranchise: { value: empresa.EMP_CODMATRIZ },
    filterVP: "PADRAO",
    filterVPMIX: "VPMIX",
  });

  useEffect(() => {
    if (Util.isPopupBlocked()) cancelDetele();

    try {
      fetchOffers();
    } catch (error) {
      handleError(error);
    }
  }, []);

  useEffect(() => {
    setFilteredOffers(
      offers.filter((offer) => {
        const searchBoxValueLower = searchBoxValue.toLowerCase();
        return (
          offer.OFE_TITULO.toLowerCase().includes(searchBoxValueLower) ||
          offer.EMP_NOMEFANTASIA.toLowerCase().includes(searchBoxValueLower)
        );
      })
    );
  }, [searchBoxValue, offers]);

  const fetchOffers = async () => {
    setIsLoading(true);

    const payload = await getOffers({
      seg_codigo:
        filters.filterSegment.length === 0
          ? null
          : `(${filters.filterSegment.map((f) => f.value).toString()})`,
      len_cidibge:
        filters.filterCity.length === 0
          ? null
          : `(${filters.filterCity.map((f) => f.value).toString()})`,
      emp_codigo: filters.filterFranchise.value,
      emp_codaux: filters.filterFranchise.value,
      ofe_tipo: `('${filters.filterVP}', '${filters.filterVPMIX}')`,
    });

    const offersSorted = payload.ofertas;

    setOffers(offersSorted);

    setIsLoading(false);
  };

  const onSelectOffer = (offer: Offer) => {
    setOffer(offer);
    Util.openModal("detalhesOferta");
  };

  const handleBuyOffer = async (offer: Offer) => {
    if (offer.OFE_ACOMBINAR === "S") {
      Util.openModal("ModalRequestQuote");
    } else {
      const {
        payload: { temCompra },
      } = await checkBuyOfferToday({
        ofe_codigo: offer.OFE_CODIGO,
        emp_codigo: empresa.EMP_CODIGO,
      });
      if (temCompra) openSweetAlertBuyToday();
      else Util.openModal("ModalPayCommission");
    }
  };

  const openSweetAlertBuyToday = () => {
    setSweetAlertBuyToday(
      <SweetAlert
        warning
        title="Atenção!"
        onConfirm={() => {
          closeSweetAlertBuyToday();
          Util.openModal("ModalPayCommission");
        }}
        onCancel={() => closeSweetAlertBuyToday()}
        allowEscape={true}
        showCancel
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="secondary"
        confirmBtnText="Sim"
        cancelBtnText="Não"
      >
        <>
          Você ja realizou a compra desta oferta hoje!
          <br />
          Deseja comprar novamente?
        </>
      </SweetAlert>
    );
  };

  const closeSweetAlertBuyToday = () => {
    setSweetAlertBuyToday(undefined);
  };

  const permuteOfferHandler = async (fpa_codigo: number, method?: string) => {
    const {
      success,
      message,
      payload: { dados },
    } = await requestQuoteAndPermuteOfferBuy({
      emp_codigo: empresa.EMP_CODIGO,
      emp_codmatriz: empresa.EMP_CODMATRIZ,
      usu_codigo: usuario.USU_CODIGO,
      ofe_codigo: offer.OFE_CODIGO,
      ofe_valor: offer.OFE_VALOR,
      fpa_codigo: fpa_codigo,
      asaas_ativo: offer.EMP_FRANQUIA_ASAAS_ATIVO,
    });

    if (!success) {
      showAlertDanger({ message });
    } else {
      if (balance)
        changeBalance(
          balance - +offer.OFE_VALOR.replace(".", "").replace(",", ".")
        );

      showAlertSuccess({
        message: "Permuta realizada com sucesso.",
        timeout: 0,
      });
      Util.openItauShopline(dados);

      /*     if (empresa.EMP_FRANQUIA_ASAAS_ATIVO === 'N') {
        showAlertSuccess({ message: 'Permuta realizada com sucesso.', timeout: 0 });
        Util.openItauShopline(dados);
      } else {
        const response = await submitPaymentAsaas(
          empresa.EMP_CODIGO,
          empresa.EMP_CODMATRIZ,
          true,
          fpa_codigo,
          dados.comissaoCompra,
          dados.lan_codigo_compra
        );

        if (response.success) {
          //Fazer cobranca do vendedor
          const { payload, success } = await calcCommission({
            ofe_codigo: offer.OFE_CODIGO,
            emp_codigo: offer.EMP_CODIGO,
            e_compra: false,
            valor: offer.OFE_VALOR,
            e_vpmix: offer.OFE_TIPO === OfferType.VPMIX,
          });

           if (success === '1') {
            const resVendedor = await submitPaymentAsaas(
              offer.EMP_CODIGO,
              offer.EMP_ID_FRANQUIA_ASAAS_ATIVO,
              false,
              fpa_codigo,
              payload.comissao,
              dados.lan_codigo
            ); // Independe do resultado, retorna sucesso para o usuario
          } 

          showAlertSuccess({ message: response.message, timeout: 0 });
        } else {
          showAlertDanger({ message: response.message, timeout: 0 });
        }

        if (response.payload?.data.idAssas) {
          window.open(response.payload.data.invoiceUrl, '_blank');
        } else {
          let message = '';
          if (Boolean(response.payload?.data.autenticacao)) {
            message = 'Integração de cobrança não permitida.\nEntre em contato com a franquia do vendedor!';
          } else if (Boolean(response.payload?.data.associado)) {
            message = 'Seu email ou telefone está inválido.\nVerifique com o responsável!';
          } else {
            message = 'Não conseguimos enviar o email com a cobrança!';
          }

          setTimeout(() => {
            showAlertDanger({ message, timeout: 0 });
          }, 3000);
        }
      } */

      Util.closeModal("ModalPayCommission");
      Util.closeModal("detalhesOferta");
    }
  };

  const requestOfferQuoteHandler = async (quoteFormData: QuoteFormData) => {
    const { success, message } = await requestQuote({
      one_descricao: quoteFormData.one_descricao,
      ofe_valor: 0,
      ofe_codigo: offer.OFE_CODIGO,
      emp_codigo: empresa.EMP_CODIGO,
      emp_codmatriz: empresa.EMP_CODMATRIZ,
    });

    if (success) {
      showAlertSuccess({ message: "O orçamento foi solicitado." });
      Util.closeModal("detalhesOferta");
      Util.closeModal("ModalRequestQuote");
    } else {
      showAlertDanger({ message });
    }
  };

  const cancelDetele = () => {
    setAlert(
      <SweetAlert
        danger
        title="Ops!"
        onConfirm={() => document.location.reload()}
        onCancel={() => {}}
        confirmBtnBsStyle="info"
        allowEscape={false}
      >
        <b>O Bloqueador de Pop-up está ativado para o sistema.</b>
        <br />
        <br />
        Favor permitir em seu navegador que o site{" "}
        <b>{window.location.origin}</b> possa abrir Pop-up!
        <br />
        <br />
        <div style={{ fontSize: 16, textAlign: "left" }}>
          <b>Chrome:</b> Configurações {">"} Privacidade {">"} Configurações de
          Conteúdo {">"} Pop-ups {">"} Gerenciar Exceções
          <br />
          <br />
          <b>Firefox:</b> Opções {">"} Conteúdo {">"} Janelas popup {">"}{" "}
          Exceções
          <br />
          <br />
          <b>Safari Mac:</b> Safari {">"} Preferências {">"} Websites {">"}{" "}
          Ative
          <br />
          <br />
          <b>Safari Iphone:</b> Configurações {">"} Safari {">"} Bloquear
          pop-ups {">"} Ative
        </div>
      </SweetAlert>
    );
  };

  return (
    <ViewVerOfertas
      isLoading={isLoading}
      ModalFilter={
        <ModalFilterAvailableOffers
          fetchOffers={fetchOffers}
          filtersState={{ filters: filters, setFilters: setFilters }}
        />
      }
      ModalOfferDetails={
        <ModalOfferDetails
          offer={offer}
          enableOffer
          mustSaveVisit
          onSubmit={handleBuyOffer}
        />
      }
      offers={filteredOffers}
      onSelectOffer={onSelectOffer}
      ModalPayCommission={
        <ModalPayCommission
          emp_codigo={empresa.EMP_CODIGO.toString()}
          onSubmit={permuteOfferHandler}
          negotiation={{
            ofe_codigo: offer.OFE_CODIGO,
            emp_codigo: empresa.EMP_CODIGO,
            e_compra: true,
            valor: offer.OFE_VALOR,
            e_vpmix: offer.OFE_TIPO === OfferType.VPMIX,
            isPaid: false,
          }}
        />
      }
      ModalRequestQuote={
        <ModalRequestQuote onSubmit={requestOfferQuoteHandler} offer={offer} />
      }
      alert={alert}
      sweetAlertBuyToday={sweetAlertBuyToday}
      headerConfig={{
        title: "Ofertas disponíveis",
        showBalance: true,
        searchBoxState: {
          searchBoxValue: searchBoxValue,
          setSearchBoxValue: setSearchBoxValue,
        },
      }}
    />
  );
};

export default VerOfertas;
