import React from 'react';
import { BasicInputProps } from '~/types';
import LoadingFormSpin from '~/components/loading/FormSpin';
import { Controller } from 'react-hook-form';

const BasicInput: React.FunctionComponent<BasicInputProps> = ({
  label,
  control,
  hasError,
  isLoading,
  isRequired,
  type,
  isDisabled,
  keyComponent,
  readOnly,
  name,
  defaultValue,
  placeholder,
  autoComplete,
  size,
  maxLength,
  max,
  minLength,
  min,
}) => {
  return (
    <div className={`form-group form-loader ${hasError === undefined ? '' : hasError ? 'has-error' : 'has-success'}`}>
      {type === 'hidden' ? null : (
        <label>
          {label}
          {isRequired && <span className="star" />}
        </label>
      )}
      <Controller
        key={keyComponent}
        name={name}
        control={control}
        defaultValue={
          type === 'number'
            ? Number(defaultValue) || undefined
            : type === 'date' || type === 'datetime-local'
            ? defaultValue || undefined
            : defaultValue
        }
        render={({ onChange, value }) => (
          <input
            autoComplete={autoComplete || type === 'password' ? 'current-password' : undefined}
            type={type}
            disabled={isDisabled}
            readOnly={readOnly}
            placeholder={placeholder}
            size={size}
            maxLength={maxLength}
            minLength={minLength}
            max={max}
            min={min}
            value={value ?? ''}
            onChange={(e) => {
              const value = e.target.value;
              if (type === 'number') onChange(value === '' ? undefined : +value);
              else if (type === 'date' || type === 'datetime-local') onChange(value === '' ? undefined : value);
              else onChange(value);
            }}
            className={'form-control'}
          />
        )}
      />
      {isLoading && <LoadingFormSpin />}
    </div>
  );
};

export default BasicInput;
