import React, { useContext } from 'react';
import { useState } from 'react';
import LoadingSpin from '~/components/loading/LoadingSpin';
import { printVoucher } from '~/service/associado/VoucherService';
import { LastTransaction, LastTransactionsProps } from '~/types';
import {
  closeModal,
  formatNumberWithoutPrefix,
  openInNewWindow,
  openItauShopline,
  openModal,
  parseTransactionArray,
} from '~/utils/util';
import ModalPayCommission from '~/components/modal/ModalPayCommission';
import { AuthContext } from '~/context/AuthContext';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';
import { handleResendPayment, permuteOfferBuy, permuteOfferSell, resendPayment } from '~/service/NegotiationService';
import { submitPaymentAsaas } from '~/service/PaymentAsaas';
import { OfferType } from '~/enum';

const LastTransactions: React.FunctionComponent<LastTransactionsProps> = ({ loading, title, subtitle, tableData }) => {
  const { company: empresa, user: usuario } = useContext(AuthContext);

  const [negotiation, setNegotiation] = useState<LastTransaction | undefined>();
  const [loadingCard, setLoadingCard] = useState(false);

  const openModalPayCommission = (offer: LastTransaction) => {
    setNegotiation(offer);
    openModal('ModalPayCommission' + title);
  };

  const payCommission = async (offer: LastTransaction) => {
    if (offer.EMP_FRANQUIA_ASAAS_ATIVO === 'S') {
      if (!offer.LAN_ID_PAYMENT || offer.LAN_RECEBIDO === 'S') {
        openModalPayCommission(offer);
      } else if (offer.LAN_ASAAS_INVOICE_URL) {
        openInNewWindow(offer.LAN_ASAAS_INVOICE_URL);
      } else {
        handlePaymentAsaas(offer);
      }
    } else {
      const transaction = parseTransactionArray(offer, title === 'Vendas');

      if (!transaction || transaction.tra_pedido === undefined || transaction.tra_pedido === '') {
        openModalPayCommission(offer);
      } else {
        handleResendPayment({
          ofe_codigo: offer.OFE_CODIGO,
          emp_codigo: empresa.EMP_CODIGO.toString(),
          emp_codmatriz: empresa.EMP_CODMATRIZ.toString(),
          tra_tipo: title === 'Compras' ? 'Compra' : 'Venda',
          tra_codigo: transaction.tra_codigo,
        });
      }
    }
  };

  const permuteOfferHandler = async (fpa_codigo: number) => {
    if (!negotiation) {
      showAlertDanger({ message: 'Erro interno. Por favor, tente novamente.' });
    } else if (negotiation.EMP_FRANQUIA_ASAAS_ATIVO === 'S') {
      handlePaymentAsaas(negotiation, fpa_codigo);
    } else {
      const transaction = parseTransactionArray(negotiation, title === 'Vendas');

      if (transaction) {
        const body = {
          emp_codigo: empresa.EMP_CODIGO,
          emp_codmatriz: empresa.EMP_CODMATRIZ,
          usu_codigo: usuario.USU_CODIGO,
          ofe_codigo: negotiation.OFE_CODIGO,
          ofe_valor: negotiation.ONE_VALOR,
          fpa_codigo: fpa_codigo,
          orc_codigo: negotiation.ORC_CODIGO,
        };
        const {
          success,
          message,
          payload: { dados },
        } = title === 'Vendas'
          ? await permuteOfferSell({ ...body, tra_codigo: transaction.tra_codigo })
          : await permuteOfferBuy({ ...body, one_descricao: '' });

        if (!success) {
          showAlertDanger({ message });
        } else {
          openItauShopline(dados);
        }
      }
    }

    closeModal('ModalPayCommission' + title);
  };

  const handlePaymentAsaas = async (offer: LastTransaction, fpa_codigo?: number) => {
    setLoadingCard(true);

    const response = await submitPaymentAsaas(
      empresa.EMP_CODIGO,
      empresa.EMP_CODMATRIZ,
      title === 'Compras' ? true : false,
      fpa_codigo ? fpa_codigo : offer.FPA_CODIGO_PAYMENT,
      title === 'Compras' ? offer.LAN_VALOR_COMPRADOR : offer.LAN_VALOR_VENDEDOR,
      title === 'Compras' ? offer.LAN_CODIGO_COMPRADOR : offer.LAN_CODIGO_VENDEDOR,
      offer.LAN_ID_PAYMENT
    );

    setLoadingCard(false);

    if (response.success) {
      showAlertSuccess({ message: response.message, timeout: 0 });
    } else {
      showAlertDanger({ message: response.message, timeout: 0 });
    }

    if (response.payload?.data.idAssas) {
      openInNewWindow(response.payload.data.invoiceUrl);
    } else {
      let message = '';
      if (Boolean(response.payload?.data.autenticacao)) {
        message = 'Integração de cobrança não permitida.\nEntre em contato com a franquia do vendedor!';
      } else if (Boolean(response.payload?.data.associado)) {
        message = 'Seu email ou telefone está inválido.\nVerifique com o responsável!';
      } else {
        message = 'Não conseguimos enviar o email com a cobrança!';
      }

      setTimeout(() => {
        showAlertDanger({ message, timeout: 0 });
      }, 3000);
    }
  };

  return (
    <>
      <div className="card-header ">
        <h4 className="card-title">{title}</h4>
        <p className="card-category">{subtitle}</p>
      </div>
      <div className="card-body">
        <table className="table" style={{ tableLayout: 'fixed' }}>
          <colgroup>
            <col width="45%" />
            <col />
            <col />
            <col width="15%" />
          </colgroup>
          <thead>
            <tr>
              <th>Oferta</th>
              <th className="text-right">Valor SC$</th>
              <th className="text-right">Data</th>
              <th className="text-right"></th>
            </tr>
          </thead>
          <tbody>
            {loadingCard ? (
              <tr>
                <td colSpan={4}>
                  <LoadingSpin />
                </td>
              </tr>
            ) : (
              tableData.map((offer, i) => (
                <tr key={`LastTransactions_Row_${title}_${i}`} style={{ height: '57px' }}>
                  <td>{offer.OFE_TITULO}</td>
                  <td className="text-right text-danger">
                    {formatNumberWithoutPrefix(+offer.ONE_VALOR.replace(',', '.'))}
                  </td>
                  <td className="text-right">{offer.ONE_DATA.split(' ')[0]}</td>
                  <td className="text-right">
                    <div className="table-actions">
                   {/*    <i
                        className="clickable fa fa-money-bill-alt"
                        style={{ color: '#8ece31', fontSize: 18 }}
                        title="pagar comissão"
                        onClick={() => payCommission(offer)}
                      /> */}
                      {title === 'Vendas' && (
                        <i
                          className="clickable fa fa-print"
                          style={{ fontSize: 18 }}
                          title="imprimir voucher"
                          onClick={() => printVoucher({ orc_codigo: offer.ORC_CODIGO, ofe_codigo: offer.OFE_CODIGO })}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <ModalPayCommission
        idModalComplement={title}
        emp_codigo={empresa.EMP_CODIGO.toString()}
        onSubmit={permuteOfferHandler}
        negotiation={
          negotiation
            ? {
                ofe_codigo: negotiation?.OFE_CODIGO,
                emp_codigo: empresa.EMP_CODIGO,
                e_compra: !!negotiation?.TRA_CODSTTPEDSITCOM,
                valor: negotiation?.ONE_VALOR,
                e_vpmix: negotiation?.OFE_TIPO === OfferType.VPMIX,
                isPaid: negotiation?.LAN_RECEBIDO === 'S',
              }
            : undefined
        }
      />
    </>
  );
};

export default LastTransactions;
